import { useSearchParams } from "@remix-run/react";
import { json } from "@remix-run/router";
import React from "react";
import type { ZodError, ZodSchema } from "zod";
import type {
  Address,
  EnigmaIDReturnObject,
  EnigmaNames,
} from "~/services/enigma.server";

const concatUrlParser = ({
  customUrl,
  key,
  value,
  withNextParam,
}: {
  customUrl: string;
  key: string;
  value: string;
  withNextParam: boolean;
}) => {
  const urlParsed = customUrl.concat(`${key}=${value}`);
  if (withNextParam) {
    return urlParsed.concat("&");
  }

  return urlParsed;
};

/**
 * @returns domain URL (without a ending slash, like: https://withhansa.com)
 */
function getDomainUrl(request: Request) {
  const host =
    request.headers.get("X-Forwarded-Host") ?? request.headers.get("host");
  if (!host) {
    throw new Error("Could not determine domain URL.");
  }
  const protocol =
    host.includes("localhost") || host.includes("192.168") ? "http" : "https";
  return `${protocol}://${host}`;
}

function getErrorMessage(error: unknown) {
  if (typeof error === "string") return error;
  if (error instanceof Error) return error.message;
  return "Unknown Error";
}

type ActionError<T> = Partial<Record<keyof T, string>>;

export type RequestBody = {
  [k: string]: FormDataEntryValue;
};

async function validateActionInput<ActionInput>({
  request,
  body,
  schema,
}: {
  request?: Request;
  body?: RequestBody;
  schema: ZodSchema;
}) {
  let dataBody: RequestBody = {};

  if (request) {
    dataBody = Object.fromEntries(await request.formData());
  }
  if (body) {
    dataBody = body;
  }
  // converting revenueStream string array to JSON array
  if (dataBody.revenueStream) {
    dataBody.revenueStream = JSON.parse(dataBody.revenueStream as string);
  }

  return tryParseActionInput<ActionInput>({
    dataBody,
    schema,
    body,
  });
}

async function validateActionInputVariant1<ActionInput>({
  request,
  body,
  schema,
  rawFormData,
}: {
  request?: Request;
  body?: RequestBody;
  schema: ZodSchema;
  rawFormData: FormData;
}) {
  let dataBody: RequestBody = {};

  if (request) {
    dataBody = Object.fromEntries(rawFormData);
  }
  if (body) {
    dataBody = body;
  }

  return tryParseActionInput<ActionInput>({
    dataBody,
    schema,
    body,
  });
}

function flattenAddress(address: Address): string {
  const {
    street_address1,
    street_address2 = "",
    city,
    state,
    postal_code,
  } = address;

  const nonNullAddress2 = street_address2 === null ? "" : street_address2;

  return `${street_address1} ${nonNullAddress2} ${city}, ${state} ${postal_code}`;
}

function getPersonFromLocation(
  registered_agents: EnigmaIDReturnObject["registered_agents"],
  associated_people: EnigmaIDReturnObject["associated_people"],
): string {
  if (associated_people[0]?.name) {
    return associated_people[0].name;
  } else if (registered_agents?.[0]) {
    return registered_agents[0];
  } else {
    return "Unknown Owner";
  }
}

function getNamefromLocation(
  names: EnigmaNames[] | undefined,
  aliases: string[] | undefined,
  matched_fields:
    | {
        name: string;
        person: string;
        address: Address;
        website: string;
      }
    | undefined,
): string {
  if (aliases && aliases.length > 0) {
    return aliases[0];
  } else if (names && names.length > 0) {
    return names[0].name;
  } else if (matched_fields) {
    return matched_fields.name;
  } else {
    return "Unknown Business Name";
  }
}

function objectToURL(
  baseURL: string,
  object: Record<string, string | undefined>,
): string {
  let url = `${baseURL}?`;

  const paramKeys = Object.keys(object);
  return paramKeys.reduce((customUrl, paramKey, index) => {
    const value = object[paramKey];
    if (value) {
      if (paramKeys.length - 1 !== index) {
        return concatUrlParser({
          customUrl,
          key: paramKey,
          value: encodeURIComponent(value),
          withNextParam: true,
        });
      }

      return concatUrlParser({
        customUrl,
        key: paramKey,
        value: encodeURIComponent(value),
        withNextParam: false,
      });
    } else {
      return customUrl;
    }
  }, url);
}

async function promiseProps<T extends Record<string, any>>(
  obj: T,
): Promise<{ [key in keyof T]: Awaited<T[key]> }> {
  const arr = await Promise.all(
    Object.entries(obj).map(async ([key, promise]) => {
      const resolve = await promise;
      return {
        key,
        resolve,
      };
    }),
  );

  return arr.reduce(
    (newObj, { key, resolve }) => {
      // Just to satisfy TSC
      if (key in obj) {
        const narrow: keyof T = key;
        newObj[narrow] = resolve;
      }
      return newObj;
    },
    {} as { [key in keyof T]: Awaited<T[key]> },
  );
}

function safeURLTag(
  strings: TemplateStringsArray,
  ...expressions: string[]
): string {
  const result: string[] = [];

  for (let i = 0; i < expressions.length; i++) {
    result.push(strings[i]);
    result.push(encodeURIComponent(expressions[i]));
  }

  result.push(strings[strings.length - 1]);

  return result.join("");
}

export {
  flattenAddress,
  getDomainUrl,
  getErrorMessage,
  validateActionInput,
  validateActionInputVariant1,
  getPersonFromLocation,
  getNamefromLocation,
  objectToURL,
  promiseProps,
  safeURLTag,
};

interface SearchQueryParamsInterface {
  firstName: string;
  lastName: string;
  businessName: string;
  ownerName: string;
  streetAddress: string;
  city: string;
  state: string;
  postalCode: string;
  website: string;
  phone: string;
}

export function useSearchQueryParams(): SearchQueryParamsInterface {
  const [queryParams, setQueryParams] =
    React.useState<SearchQueryParamsInterface>({
      firstName: "",
      lastName: "",
      businessName: "",
      ownerName: "",
      streetAddress: "",
      city: "",
      state: "",
      postalCode: "",
      website: "",
      phone: "",
    });
  const [searchParams] = useSearchParams();

  React.useState(() => {
    const firstName = searchParams.get("first_name") || "";
    const lastName = searchParams.get("last_name") || "";

    setQueryParams({
      firstName,
      lastName,
      businessName: searchParams.get("business_name") || "",
      ownerName: `${firstName} ${lastName} `,
      streetAddress: searchParams.get("street_address1") || "",
      city: searchParams.get("city") || "",
      state: searchParams.get("state") || "",
      postalCode: searchParams.get("postal_code") || "",
      website: searchParams.get("website") || "",
      phone: searchParams.get("phone") || "",
    });
  });

  return queryParams;
}

type WindowDimensions = {
  width: number;
  height: number;
};

const getWindowDimensions = (): WindowDimensions => {
  const { innerWidth, innerHeight } = window;
  return {
    width: innerWidth,
    height: innerHeight,
  };
};

export const useWindowDimensions = (): WindowDimensions => {
  const [windowDimensions, setWindowDimensions] =
    React.useState<WindowDimensions>({ width: 0, height: 0 });

  const handleResize = () => {
    setWindowDimensions(getWindowDimensions());
  };

  React.useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
};

export enum TailwindBreakpoint {
  Mobile = "mobile",
  Small = "sm",
  Medium = "md",
  Large = "lg",
  ExtraLarge = "xl",
  DoubleExtraLarge = "2xl",
}

// As documented in tailwind docs => https://tailwindcss.com/docs/responsive-design
export const useTailwindBreakpoint = (): TailwindBreakpoint => {
  const { width } = useWindowDimensions();
  if (width <= 640) {
    return TailwindBreakpoint.Mobile;
  } else if (width <= 768) {
    return TailwindBreakpoint.Small;
  } else if (width <= 1024) {
    return TailwindBreakpoint.Medium;
  } else if (width <= 1280) {
    return TailwindBreakpoint.Large;
  } else {
    return TailwindBreakpoint.ExtraLarge;
  }
};

export const badRequest = (data: unknown, init?: ResponseInit) =>
  json(data, { status: 400, ...init });

export const notFound = (data: unknown) => json(data, { status: 404 });

export async function parseRequest<T>(request: Request, schema: ZodSchema<T>) {
  const type = request.headers.get("content-type");
  console.log({ type });
  if (type === "application/json") {
    return schema.safeParse(await request.json());
  }
  return schema.safeParse(Object.fromEntries(await request.formData()));
}

export const generateSecret = (length = 6) => {
  const characters = "ABCDEFGHIJKLMNOPQRSTUVXYZ0123456789";
  const charactersLength = characters.length;
  let randomString = "";

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * charactersLength);
    randomString += characters.charAt(randomIndex);
    if ((1 + i) % 3 === 0 && i + 1 !== length) randomString += "-";
  }

  return randomString;
};

function tryParseActionInput<ActionInput>({
  schema,
  dataBody,
  body,
}: {
  body?: RequestBody;
  schema: ZodSchema;
  dataBody: RequestBody;
}) {
  try {
    const formData = schema.parse(dataBody) as ActionInput;
    return {
      formData,
      errors: null,
    };
  } catch (error) {
    const errors = error as ZodError<ActionInput>;
    return {
      formData: dataBody ?? body,
      errors: errors.issues.reduce((acc: ActionError<ActionInput>, curr) => {
        const key = curr.path[0] as keyof ActionInput;
        acc[key] = curr.message;
        return acc;
      }, {}),
    };
  }
}
